export const defaultButtonStyle = {
  fontSize: '0.875rem',
  borderRadius: '10px',
  border: '1px solid #393E46',
  backgroundColor: '#393E46',
  color: '#FFFFFF',
  ':hover': {
    backgroundColor: '#595E66',
  },
  ':focus': {
    boxShadow: 'none',
  },
}
