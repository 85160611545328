import { clusterApiUrl, PublicKey, Connection } from '@solana/web3.js'

console.log('DATABASE_URL', process.env.DATABASE_URL)

export const configPerEnv = {
  dev: {
    host: 'localhost:3000',
    solanaEnv: 'devnet',
    apiHost: 'http://localhost:4000',
    rpcHost: true
      ? 'https://devnet.genesysgo.net/'
      : clusterApiUrl('devnet'),
    puffToken: '8crBEjMoyGmUA4jsus4oNbzmS9skroExSVzhroidjaA6',
    //usdcToken: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    solToken: 'So11111111111111111111111111111111111111112',
    candyMachineId: 'SgE3PvVMVEJYMyS5YxmL64gu8wKcByseAqbKhobcSfp',
  },
  production: {
    host: 'https://www.stonedapecrew.com',
    solanaEnv: 'mainnet-beta',
    apiHost: 'https://sac-discord-bot-5ptq.onrender.com',
    rpcHost: true
      ? 'https://late-falling-firefly.solana-mainnet.quiknode.pro/812b7e0ab8d2b7589b67bf72b7f1295563c2ce97/'
      : 'https://ssc-dao.genesysgo.net/',
    puffToken: 'G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB',
    //usdcToken: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    solToken: 'So11111111111111111111111111111111111111112',
    candyMachineId: '7RCBr3ZQ8yhY4jHpFFo3Kmh7MnaCPi1bFuUgXUB9WURf',
  },
}

export const ENV =
  (process.env.NEXT_PUBLIC_ENV as 'production' | undefined) ?? 'dev'
const config = configPerEnv[ENV]

export default config

export const GA_TRACKING_ID = 'G-D2T90XB3KR'

const rpcHost = config.rpcHost
export const connection = new Connection(rpcHost, {
  commitment: 'confirmed',
  httpHeaders: {
    referer: 'https://www.stonedapecrew.com',
  },
})

//export const usdcToken = new PublicKey(config.usdcToken)
export const puffToken = new PublicKey(config.puffToken)
export const solToken = new PublicKey(config.solToken)

export const jwtKey = process.env.JWT_SECRET!

export function getBaseUrl() {
  if (typeof window !== 'undefined') {
    return ''
  }
  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`
  }

  // // reference for render.com
  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}